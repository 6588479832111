import Choices from 'choices.js';



class Elements{
    constructor(){
        this.selects = document.querySelector('select') ? document.querySelectorAll('select') : '';


        !this.selects || this.selects.forEach(el => new Choices(el, {
            searchEnabled: false,
            searchChoices: false,
            itemSelectText: ''
        }));
    }
}

export default  Elements;