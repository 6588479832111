import Elements from "./components/elements";
import Header from "./components/header";
import Sections from "./components/sections";

function start(){
    // start content
    new Header();
    new Elements();
    new Sections();
}




document.addEventListener('DOMContentLoaded', start(), false);