import Swiper from 'swiper/js/swiper.js';

class Sections{
    constructor() {
        this.section_slick = document.querySelector('.section_slick') ? document.querySelectorAll('.section_slick') : null;
        this.slider_gallery = document.querySelector('.slider_gallery') ? document.querySelectorAll('.slider_gallery') : null;

        
        [this.section_slick, this.slider_gallery].forEach(item => !item || item.forEach(el => this.sSwiper(el)));
        
    }

    sSwiper(el){
        new Swiper(el.querySelector(".mySwiper"), {
            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    
    }
}

export default  Sections;